import React, { useEffect } from 'react'
import PageTemplate from 'Components/PageTemplate'
import Header from 'Components/Header'
import Footer from 'Components/Footer'
import useWindowDimensions from 'tools/useWindowDimensions'
import 'react-multi-carousel/lib/styles.css'
import { Transition } from '@headlessui/react'
import Carousel from 'Components/UI/Carousel'
import { isMobile } from 'react-device-detect'
import {
  MUSIC_VIDEOS,
  COMMERCIALS,
  LIVESESSIONS,
  ALL_FILMS
} from './constants'

export default function Sessions (props) {
  const pathName = props?.location?.pathname
  const [active, setActive] = React.useState(false)

  const toggleMenu = () => {
    setActive(!active)
    !active
      ? document.body.classList.add('overflow-hidden')
      : document.body.classList.remove('overflow-hidden')

    return true
  }

  return (
    <PageTemplate
      active={active}
      toggleMenu={toggleMenu}
      Header={<Header pathName={pathName} active={active} toggleMenu={toggleMenu} justNav />}
      Content={<SessionsContent />}
      Footer={<Footer />}
    />
  )
}

const SessionsContent = () => {
  // const { width } = useWindowDimensions()
  const [playState, setPlay] = React.useState({ active: false, videoId: null })

  const toggleModal = (videoId) => {
    setPlay({ active: true, videoId })
    playState.active === false
      ? document.body.classList.add('overflow-hidden')
      : document.body.classList.remove('overflow-hidden')
  }

  const closeModal = () => {
    setPlay({ ...playState, active: false })
    document.body.classList.remove('overflow-hidden')
  }

  return (
    <div className='min-h-screen bg-primary text-white'>
      <div className='sm:pl-8 pt-32 pb-32 lg:pt-40 xl:pt-40'>
        <div className='font-large text-xl mb-8'>
          <div className='mb-6 pl-8 sm:pl-0 sm:text-center'>musicvideo</div>
          <MusicVideos toggleModal={toggleModal} />
        </div>
        <div className='font-large text-xl mb-8'>
          <div className='mb-6 pl-8 sm:pl-0 sm:text-center'>commercial</div>
          <Commercials toggleModal={toggleModal} />
        </div>
        <div className='font-large text-xl mb-8'>
          <div className='mb-6 pl-8 sm:pl-0 sm:text-center'>livesession</div>
          <Livesessions toggleModal={toggleModal} />
        </div>
      </div>
      <Transition
        show={playState.active}
        enter='transition-opacity duration-200'
        enterFrom='opacity-0'
        enterTo='opacity-100'
        leave='transition-opacity duration-1000'
        leaveFrom='opacity-100'
        leaveTo='opacity-0'
      ><Modal playState={playState} closeModal={closeModal} />
      </Transition>
    </div>
  )
}

const MusicVideos = ({ toggleModal }) => {
  const CarouselContent = MUSIC_VIDEOS.map((item, i) => {
    return (
      <VideoItem key={i} onClick={toggleModal} img={item.thumbnail} videoId={item.vimeoId} />
    )
  })

  return (
    <Carousel Content={CarouselContent} />
  )
}

const Commercials = ({ toggleModal }) => {
  const CarouselContent = COMMERCIALS.map((item, i) => {
    return (
      <VideoItem key={i} onClick={toggleModal} img={item.thumbnail} videoId={item.vimeoId} />
    )
  })
  return (
    <Carousel Content={CarouselContent} />
  )
}

const Livesessions = ({ toggleModal }) => {
  const CarouselContent = LIVESESSIONS.map((item, i) => {
    return (
      <VideoItem key={i} onClick={toggleModal} img={item.thumbnail} videoId={item.vimeoId} />
    )
  })
  return (
    <Carousel Content={CarouselContent} />
  )
}

const ModalPics = ({ pics }) => {
  return pics.map((item, i) => {
    return (
      <img loading='lazy' className='rounded-lg shadow-md w-36 sm:w-40 lg:w-64 xl:w-72 m-2' key={i} src={item} alt={i} />
    )
  })
}

const Modal = ({ playState = {}, closeModal }) => {
  const [playVideo, setPlayVideo] = React.useState(false)

  const toggleVideo = () => {
    setPlayVideo(!playVideo)
    return true
  }

  const modalRef = React.createRef()
  const handleClickOutside = e => {
    if (modalRef && modalRef.current && !modalRef.current.contains(e.target)) {
      closeModal()
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [modalRef])

  const sessionItem = ALL_FILMS.find(item => item.vimeoId === playState.videoId)
  // const responsiveClassNames = 'sm:bottom-auto sm:w-2/3 w-full sm:rounded-b-lg'

  return (
    <div className='fixed z-75 inset-0'>
      <div className='fixed inset-0 transition-opacity' aria-hidden='true'>
        <div className='absolute inset-0 bg-secondary opacity-25' />
      </div>
      <Transition.Child
        enter='transition ease-in-out duration-700 transform'
        enterFrom='translate-y-full'
        enterTo='-translate-y-0'
        leave='transition ease-in-out duration-1000 transform'
        leaveFrom='translate-y-0'
        leaveTo='translate-y-full'
      >
        <div className='w-full h-screen flex justify-center items-center'>
          <div ref={modalRef} className='modal mt-20 fixed bottom-0 left-0 right-0 top-0 sm:inset-x-5 md:inset-x-40 lg:inset-x-60 xl:inset-x-120 overflow-y-auto rounded-t-lg flex flex-col bg-primary'>
            <div className='mb-8'>
              <ModalVideo playVideo={playVideo} playState={playState} toggleVideo={toggleVideo} sessionItem={sessionItem} />
              <div className='w-full py-2 px-4 sm:py-4 sm:px-6 md:py-6 md:px-8 lg:py-8 lg:px-12 xl:py-10 xl:px-20'>
                <div className='whitespace-pre-wrap'>{sessionItem && sessionItem.text}</div>
                <div className='flex flex-wrap w-full justify-between'>
                  <ModalPics pics={sessionItem.pics} />
                </div>
                {sessionItem.makingOfVimeo ? <MakingOfVideo vimeoId={sessionItem.makingOfVimeo} /> : ''}
                <div className='whitespace-pre-wrap font-basic p-2 mb-4'>{sessionItem && sessionItem.credits}</div>
                <div className='flex flex-wrap w-full justify-between'>
                  {sessionItem.bts ? <ModalPics pics={sessionItem.bts} /> : ''}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Transition.Child>
    </div>
  )
}

const MakingOfVideo = ({ vimeoId }) => {
  return (
    <div className='iframe-container-mo rounded-lg m-2 mt-4'>
      <VimeoIframe vimeoId={vimeoId} muted={0} loop={0} />
    </div>
  )
}

const ModalVideo = ({ playVideo = false, playState = {}, toggleVideo, sessionItem = {} }) => {
  const iframeContainerStyle = [653700594, 648580446, 630441064].includes(playState.videoId) && !isMobile
    ? 'iframe-container-special-p'
    : 'iframe-container'

  return (
    <div>
     {/* <div style={{ paddingTop: '42%' }} className='iframe-gradient z-100 w-full absolute bg-gradient-to-b from-transparent via-transparent to-primary opacity-100' */}
      <div className={iframeContainerStyle}>
        <VimeoIframe vimeoId={playState.videoId} autoPlay={1} />
      </div>
    </div>
  )
}

const VideoItem = ({ videoId = null, creator, title, img, onClick = () => {} }) => {
  const hoverClasses = isMobile ? '' : 'transition duration-500 ease-in-out transform hover:scale-105'
  const mobileClasses = isMobile ? { height: '200px' } : ''
  return (
    <div onClick={() => onClick(videoId)} style={{ ...mobileClasses }} className={`rounded-lg mt-4 mb-6 cursor-pointer ${hoverClasses}`}>
      <img style={{ ...mobileClasses }} className={`object-cover rounded-lg shadow-md ${hoverClasses}`} src={img} alt='posterPic' />
    </div>
  )
}

const VimeoIframe = ({ vimeoId, autoPlay = 0, loop = 1, muted = 1 }) => {
  return (
    <iframe title='vimeo-iframe' src={`https://player.vimeo.com/video/${vimeoId}?autoplay=${autoPlay}&loop=${loop}&autopause=0&muted=${muted}`} allow='autoplay; fullscreen' allowFullScreen muted />
  )
}
