import React from 'react'
import Carousel from 'react-multi-carousel'
import { isMobile } from 'react-device-detect'

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1290 },
    items: 1,
    slidesToSlide: 1,
    partialVisibilityGutter: 40
  },
  tablet: {
    breakpoint: { max: 1290, min: 736 },
    items: 1,
    slidesToSlide: 1,
    partialVisibilityGutter: 10
  },
  mobile: {
    breakpoint: { max: 735, min: 0 },
    items: 1,
    slidesToSlide: 1,
    partialVisibilityGutter: 50
  }
}

const CarouselSlider = ({ Content }) => {
  return (
    <Carousel
      arrows={!isMobile}
      additionalTransfrom={0}
      autoPlaySpeed={3000}
      centerMode={!isMobile}
      className='carousel-container'
      draggable={isMobile}
      focusOnSelect={false}
      infinite
      itemClass=''
      minimumTouchDrag={80}
      renderButtonGroupOutside={false}
      renderDotsOutside={false}
      responsive={responsive}
      sliderClass=''
      slidesToSlide={1}
      swipeable={isMobile}
      partialVisible={isMobile}
    >
      {Content}
    </Carousel>
  )
}

export default CarouselSlider
