import React from 'react'
import './App.css'
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'
import Home from 'Home/index.js'
import Sessions from 'Sessions/index.js'
// import VR from 'Vr/index.js'
import About from 'About/index.js'
import Contact from 'Contact/index.js'
import Imprint from 'Components/Imprint'
import Policy from 'Components/Policy'
// import Auth from 'Auth/index.js'
import './tailwind.output.css'

// const PrivateRoute = ({ authenticated, component: Component, path }) => {
//   return (
//     <Route
//       path={path} render={props => authenticated
//         ? <Component {...props} />
//         : <Redirect to='/signin' />}
//     />
//   )
// }

// const PublicRoute = ({ authenticated, component: Component, restricted, ...rest }) => {
//   return (
//     <Route
//       {...rest}
//       render={props => authenticated && restricted
//         ? <Navigate to='/' />
//         : <Component {...props} />}
//     />
//   )
// }

const App = () => {
  // const authenticated = useSelector(state => state.Auth.user)
  // const authenticated = false
  return (
    <Router>
      <Routes>
        {/* <PrivateRoute authenticated={authenticated} restricted component={Home} path='/' exact /> */}
        {/* <PublicRoute authenticated={authenticated} restricted component={Auth} path='/signin' exact /> */}
        <Route exact path='/' element={<Home />} />
        <Route exact path='/film' element={<Sessions />} />
        <Route exact path='/about' element={<About />} />
        <Route exact path='/contact' element={<Contact />} />
        <Route exact path='/imprint' element={<Imprint />} />
        <Route exact path='/policy' element={<Policy />} />
      </Routes>
    </Router>
  )
}

export default App
