export const LOGIN_REQUESTED = 'auth/LOGIN_REQUESTED'
export const LOGIN_SUCCEEDED = 'auth/LOGIN_SUCCEEDED'
export const LOGIN_FAILED = 'auth/LOGIN_FAILED'

export const SIGNUP_REQUESTED = 'auth/SIGNUP_REQUESTED'
export const SIGNUP_SUCCEEDED = 'auth/SIGNUP_SUCCEEDED'
export const SIGNUP_FAILED = 'auth/SIGNUP_FAILED'

export const CREATE_NEW_USER_REQU = 'auth/CREATE_NEW_USER_REQU'
export const CREATE_NEW_USER_SUCC = 'auth/CREATE_NEW_USER_SUCC'
export const CREATE_NEW_USER_FAIL = 'auth/CREATE_NEW_USER_FAIL'

export const REGISTER_NEW_USER_REQU = 'auth/REGISTER_NEW_USER_REQU'
export const REGISTER_NEW_USER_SUCC = 'auth/REGISTER_NEW_USER_SUCC'
export const REGISTER_NEW_USER_FAIL = 'auth/REGISTER_NEW_USER_FAIL'

export const FETCH_USER_REQU = 'auth/FETCH_USER_REQU'
export const FETCH_USER_SUCC = 'auth/FETCH_USER_SUCC'
export const FETCH_USER_FAIL = 'auth/FETCH_USER_FAIL'

export const CHANGE_USER_DATA = 'auth/CHANGE_USER_DATA'
