import React from 'react'
import PageTemplate from 'Components/PageTemplate'
import Header from 'Components/Header'
import Footer from 'Components/Footer'

export default function Policy (props) {
  const pathName = props?.location?.pathname
  const [active, setActive] = React.useState(false)
  const toggleMenu = () => {
    setActive(!active)
    !active
      ? document.body.classList.add('overflow-hidden')
      : document.body.classList.remove('overflow-hidden')

    return true
  }

  return (
    <PageTemplate
      active={active}
      toggleMenu={toggleMenu}
      Header={<Header pathName={pathName} active={active} toggleMenu={toggleMenu} justNav />}
      Content={<PolicyContent />}
      Footer={<Footer />}
    />
  )
}

const PolicyContent = () => {
  return (
    <div className='min-h-screen w-full bg-primary text-white flex justify-center flex-col pt-32 pb-24 pl-12 xl:pl-20 lg:pl-20 font-basic'>
      <div className='w-2/3'>
        <h1 className='font-large text-2xl'>Datenschutzerklärung</h1>
        <p>Verantwortlicher im Sinne der Datenschutzgesetze, insbesondere der EU-Datenschutzgrundverordnung (DSGVO), ist:</p>
        <p>Richard Adam</p>
        <h2 className='font-large text-xl mt-4'>Ihre Betroffenenrechte</h2>
        <p>Unter den angegebenen Kontaktdaten unseres Datenschutzbeauftragten können Sie jederzeit folgende Rechte ausüben:</p>
        <ul>
          <li>Auskunft über Ihre bei uns gespeicherten Daten und deren Verarbeitung (Art. 15 DSGVO),</li>
          <li>Berichtigung unrichtiger personenbezogener Daten (Art. 16 DSGVO),</li>
          <li>Löschung Ihrer bei uns gespeicherten Daten (Art. 17 DSGVO),</li>
          <li>Einschränkung der Datenverarbeitung, sofern wir Ihre Daten aufgrund gesetzlicher Pflichten noch nicht löschen dürfen (Art. 18 DSGVO),</li>
          <li>Widerspruch gegen die Verarbeitung Ihrer Daten bei uns (Art. 21 DSGVO) und</li>
          <li>Datenübertragbarkeit, sofern Sie in die Datenverarbeitung eingewilligt haben oder einen Vertrag mit uns abgeschlossen haben (Art. 20 DSGVO).</li>
        </ul>
        <p>Sofern Sie uns eine Einwilligung erteilt haben, können Sie diese jederzeit mit Wirkung für die Zukunft widerrufen.</p>
        <p>Sie können sich jederzeit mit einer Beschwerde an eine Aufsichtsbehörde wenden, z. B. an die zuständige Aufsichtsbehörde des Bundeslands Ihres Wohnsitzes oder an die für uns als verantwortliche Stelle zuständige Behörde.</p>
        <p>Eine Liste der Aufsichtsbehörden (für den nichtöffentlichen Bereich) mit Anschrift finden Sie unter: <a className='break-words' href='https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html' target='_blank' rel='nofollow noopener noreferrer'>https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html</a>.</p>
        <h2 className='font-large text-xl mt-4'>Kontaktformular</h2>
        <h3>Art und Zweck der Verarbeitung:</h3>
        <p>Die von Ihnen eingegebenen Daten werden zum Zweck der individuellen Kommunikation mit Ihnen gespeichert. Hierfür ist die Angabe einer validen E-Mail-Adresse sowie Ihres Namens erforderlich. Diese dient der Zuordnung der Anfrage und der anschließenden Beantwortung derselben. Die Angabe weiterer Daten ist optional.</p>
        <h3>Rechtsgrundlage:</h3>
        <p>Die Verarbeitung der in das Kontaktformular eingegebenen Daten erfolgt auf der Grundlage eines berechtigten Interesses (Art. 6 Abs. 1 lit. f DSGVO).</p>
        <p>Durch Bereitstellung des Kontaktformulars möchten wir Ihnen eine unkomplizierte Kontaktaufnahme ermöglichen. Ihre gemachten Angaben werden zum Zwecke der Bearbeitung der Anfrage sowie für mögliche Anschlussfragen gespeichert.</p>
        <p>Sofern Sie mit uns Kontakt aufnehmen, um ein Angebot zu erfragen, erfolgt die Verarbeitung der in das Kontaktformular eingegebenen Daten zur Durchführung vorvertraglicher Maßnahmen (Art. 6 Abs. 1 lit. b DSGVO).</p>
        <h3>Empfänger:</h3>
        <p>Empfänger der Daten sind ggf. Auftragsverarbeiter.</p>
        <p /><h3>Speicherdauer:</h3>
        <p>Daten werden spätestens 6 Monate nach Bearbeitung der Anfrage gelöscht.</p>
        <p>Sofern es zu einem Vertragsverhältnis kommt, unterliegen wir den gesetzlichen Aufbewahrungsfristen nach HGB und löschen Ihre Daten nach Ablauf dieser Fristen. </p>
        <h3>Bereitstellung vorgeschrieben oder erforderlich:</h3>
        <p>Die Bereitstellung Ihrer personenbezogenen Daten erfolgt freiwillig. Wir können Ihre Anfrage jedoch nur bearbeiten, sofern Sie uns Ihren Namen, Ihre E-Mail-Adresse und den Grund der Anfrage mitteilen.</p>
        <p /><h2 className='font-large text-xl mt-4'>Verwendung von Typekit-Webschriftarten von Adobe Fonts</h2>
        <p>Wir setzen Typekit-Webschriftarten von Adobe Fonts zur visuellen Gestaltung unserer Website ein. Adobe Fonts ist ein Dienst der Adobe Systems Software Ireland Companies (4-6 Riverwalk, Citywest Business Campus, Dublin 24, Republic of Ireland; nachfolgend „Adobe“), der uns den Zugriff auf eine Schriftartenbibliothek gewährt. </p>
        <p>Zur Einbindung der von uns benutzten Schriftarten muss Ihr Browser eine Verbindung zu einem Server von Adobe in den USA aufbauen und die für unsere Website benötigte Schriftart herunterladen. Adobe erhält hierdurch die Information, dass von Ihrer IP-Adresse unsere Website aufgerufen wurde. </p>
        <p>Weitere Informationen zu Adobe Fonts finden Sie in den Datenschutzhinweisen von Adobe Fonts, die Sie hier abrufen können: <a className='break-words' href='https://www.adobe.com/de/privacy/policies/adobe-fonts.html' rel='noopener nofollow noreferrer' target='_blank'>https://www.adobe.com/de/privacy/policies/adobe-fonts.html</a></p>
        <p>Wenn Ihr Browser Web Fonts nicht unterstützt, oder Sie Ihre Einwilligung nicht erteilen, wird eine Standardschrift von Ihrem Computer genutzt.</p>
        <h3>Widerruf der Einwilligung:</h3>
        <p>Vom Anbieter wird derzeit keine Möglichkeit für einen einfachen Opt-out oder ein Blockieren der Datenübertragung angeboten. Wenn Sie eine Nachverfolgung Ihrer Aktivitäten auf unserer Website verhindern wollen, widerrufen Sie bitte im Cookie-Consent-Tool Ihre Einwilligung für die entsprechende Cookie-Kategorie oder alle technisch nicht notwendigen Cookies und Datenübertragungen. In diesem Fall können Sie unsere Website jedoch ggfs. nicht oder nur eingeschränkt nutzen.</p>
        <p /><h2 className='font-large text-xl mt-4'>Eingebettete Vimeo Videos</h2>
        <p>Wir verwenden auf unserer Website auch Videos der Firma Vimeo. Betrieben wird das Videoportal durch Vimeo LLC, 555 West 18th Street, New York, New York 10011, USA. Mit Hilfe eines Plug-ins können wir Ihnen so interessantes Videomaterial direkt auf unserer Website anzeigen.</p>
        <h2 className='font-large text-xl mt-4'>SSL-Verschlüsselung</h2>
        <p>Um die Sicherheit Ihrer Daten bei der Übertragung zu schützen, verwenden wir dem aktuellen Stand der Technik entsprechende Verschlüsselungsverfahren (z. B. SSL) über HTTPS.</p>
        <h2 className='font-large text-xl mt-4'>Information über Ihr Widerspruchsrecht nach Art. 21 DSGVO</h2>
        <h3>Einzelfallbezogenes Widerspruchsrecht</h3>
        <p>Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung Sie betreffender personenbezogener Daten, die aufgrund Art. 6 Abs. 1 lit. f DSGVO (Datenverarbeitung auf der Grundlage einer Interessenabwägung) erfolgt, Widerspruch einzulegen; dies gilt auch für ein auf diese Bestimmung gestütztes Profiling im Sinne von Art. 4 Nr. 4 DSGVO.</p>
        <p>Legen Sie Widerspruch ein, werden wir Ihre personenbezogenen Daten nicht mehr verarbeiten, es sei denn, wir können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.</p>
        <h3>Empfänger eines Widerspruchs</h3>
        <p>Richard Adam</p>

        <h2 className='font-large text-xl mt-4'>Änderung unserer Datenschutzbestimmungen</h2>
        <p>Wir behalten uns vor, diese Datenschutzerklärung anzupassen, damit sie stets den aktuellen rechtlichen Anforderungen entspricht oder um Änderungen unserer Leistungen in der Datenschutzerklärung umzusetzen, z.B. bei der Einführung neuer Services. Für Ihren erneuten Besuch gilt dann die neue Datenschutzerklärung.</p>
        <h2 className='font-large text-xl mt-4'>Fragen an den Datenschutzbeauftragten</h2>
        <p>Wenn Sie Fragen zum Datenschutz haben, schreiben Sie uns bitte eine E-Mail oder wenden Sie sich direkt an die für den Datenschutz verantwortliche Person in unserer Organisation:</p>
        <p>info@dphns.me</p>


        <h1 className='font-large text-2xl mt-12'>Data protection</h1>
        <p>The person responsible within the meaning of the data protection laws, in particular the EU General Data Protection Regulation (GDPR), is:</p>
        <p>Richard Adam</p>
        <h2 className='font-large text-xl mt-4'>Your data subject rights</h2>
        <p>You can exercise the following rights at any time using the contact details provided for our data protection officer: </p>
        <ul>
          <li>Information about your data stored by us and their processing (Article 15 GDPR),</li>
          <li>Correction of incorrect personal data (Article 16 GDPR),</li>
          <li>Deletion of your data stored by us (Article 17 GDPR),</li>
          <li>Restriction of data processing if we are not yet allowed to delete your data due to legal obligations (Article 18 GDPR),</li>
          <li>Objection to the processing of your data by us (Article 21 GDPR) and</li>
          <li>Data transferability if you have consented to data processing or have concluded a contract with us (Article 20 GDPR).</li>
        </ul>
        <p>If you have given us your consent, you can revoke this at any time with effect for the future.</p>
        <p>You can contact a supervisory authority at any time with a complaint, e.g. B. to the competent supervisory authority of the federal state of your place of residence or to the authority responsible for us as the responsible body.</p>
        <p>You can find a list of the supervisory authorities (for the non-public area) with their addresses at: <a className='break-words' href='https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html' target='_blank' rel='nofollow noopener noreferrer'>https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html</a>.</p>
        <h2 className='font-large text-xl mt-4'>Contact form</h2>
        <h3>Type and purpose of processing:</h3>
        <p>The data you enter will be stored for the purpose of individual communication with you. This requires you to provide a valid email address and your name. This is used to allocate the request and then to answer it. Providing further data is optional.</p>
        <h3>Legal basis:</h3>
        <p>The data entered in the contact form is processed on the basis of a legitimate interest (Article 6 (1) (f) GDPR). By providing the contact form, we would like to make it easy for you to contact us. The information you provide will be stored for the purpose of processing the request and for possible follow-up questions.</p>
        <p>If you contact us to request an offer, the data entered in the contact form will be processed to carry out pre-contractual measures (Article 6 (1) (b) GDPR).</p>
        <h3>Recipient:</h3>
        <p>Recipients of the data may be processors.</p>
        <h3>Storage duration:</h3>
        <p>Data will be deleted no later than 6 months after processing the request.</p>
        <p>If there is a contractual relationship, we are subject to the statutory retention periods according to the German Commercial Code and delete your data after these periods have expired.</p>
        <h3>Provision prescribed or required:</h3>
        <p>The provision of your personal data is voluntary. However, we can only process your request if you provide us with your name, email address and the reason for the request.</p>
        <h2 className='font-large text-xl mt-4'>Using Typekit web fonts from Adobe Fonts</h2>
        <p>We use Typekit web fonts from Adobe Fonts for the visual design of our website. Adobe Fonts is a service provided by Adobe Systems Software Ireland Companies (4-6 Riverwalk, Citywest Business Campus, Dublin 24, Republic of Ireland; hereinafter "Adobe") that gives us access to a font library. To integrate the fonts we use, your browser must establish a connection to an Adobe server in the USA and download the font required for our website. This gives Adobe the information that our website was accessed from your IP address. You can find more information about Adobe Fonts in the Adobe Fonts data protection information, which you can access here: <a className='break-words' href='https://www.adobe.com/de/privacy/policies/adobe-fonts.html' rel='noopener nofollow noreferrer' target='_blank'>https://www.adobe.com/de/privacy/policies/adobe-fonts.html</a> If your browser does not support web fonts or you do not give your consent, a standard font will be used by your computer. Withdrawal of consent: The provider currently offers no option for a simple opt-out or blocking of data transmission. If you want to prevent your activities on our website from being tracked, please revoke your consent for the corresponding cookie category or all technically unnecessary cookies and data transmissions in the cookie consent tool. In this case, however, you may not be able to use our website or only to a limited extent.</p>
        <h2 className='font-large text-xl mt-4'>Embedded Vimeo videos</h2>
        <p>We also use videos from Vimeo on our website. The video portal is operated by Vimeo LLC, 555 West 18th Street, New York, New York 10011, USA. With the help of a plug-in, we can show you interesting video material directly on our website.</p>
        <h2 className='font-large text-xl mt-4'>SSL encryption</h2>
        <p>In order to protect the security of your data during transmission, we use state-of-the-art encryption methods (e.g. SSL) via HTTPS</p>
        <h2 className='font-large text-xl mt-4'>Information about your right of objection according to Art. 21 GDPR</h2>
        <h3>Individual right of objection</h3>
        <p>You have the right, for reasons arising from your particular situation, to object at any time to the processing of personal data relating to you, which is based on Article 6 (1) (f) GDPR (data processing on the basis of a balance of interests); this also applies to profiling based on this provision within the meaning of Art. 4 No. 4 GDPR.</p>
        <p>If you file an objection, we will no longer process your personal data unless we can demonstrate compelling legitimate grounds for processing that outweigh your interests, rights and freedoms, or the processing serves to assert, exercise or defend legal claims.</p>
        <h3>Recipient of an objection:</h3>
        <p>Richard Adam</p>
        <h2 className='font-large text-xl mt-4'>Change to our privacy policy</h2>
        <p>We reserve the right to adapt this data protection declaration so that it always corresponds to the current legal requirements or to implement changes to our services in the data protection declaration, e.g. when introducing new services. The new data protection declaration will then apply to your next visit.</p>
        <h2 className='font-large text-xl mt-4'>Questions to the data protection officer</h2>
        <p>If you have any questions about data protection, please send us an email or contact the person responsible for data protection in our organization directly:</p>
        <p>info@dphns.me</p>
      </div>
    </div>
  )
}
