import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import App from './App'
// import * as serviceWorker from './serviceWorker'
import { createStore, compose, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import combinedReducers from './combinedReducers.js'

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const store = createStore(
  combinedReducers,
  composeEnhancer(applyMiddleware(thunk))
)

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
)

// serviceWorker.register()
