import React, { useEffect } from 'react'
import logo from 'assets/logo.png'
// import Loop from 'assets/loop_mp4.mp4'
import breakpoints from 'tools/breakpoints'
import useWindowDimensions from 'tools/useWindowDimensions'
// import headPic from 'assets/landing_pic.jpg'
import isiOS from 'tools/checkIOS'

const Hamburger = ({ onClick, active = false }) => {
  return (
    <div onClick={() => onClick()} className='three col'>
      <div className={`hamburger ${active ? 'is-active' : ''}`} id='hamburger-1'>
        <span className='line' />
        <span className='line' />
        <span className='line' />
      </div>
    </div>
  )
}

const HeaderNavigation = ({ toggleMenu, active = false, pathName }) => {
  const { width } = useWindowDimensions()
  if (width < breakpoints.md) {
    return (
      <Hamburger onClick={toggleMenu} active={active} />
    )
  }

  const getClassName = (path) => {
    return `${path === pathName ? '' : 'border-transparent'} pb-2 border-b hover:border-white font-basic`
  }

  return (
    <div className='z-100 flex text-white'>
      <div className='pr-4'>
        <a href='/' className={getClassName('/')}>home</a>
      </div>
      <div className='pr-4'>
        <a href='/film' className={getClassName('/film')}>film</a>
      </div>
      <div className='pr-4'>
        <a href='/about' className={getClassName('/about')}>about</a>
      </div>
      <div className='pr-4'>
        <a className={getClassName('/contact')} href='/contact'>contact</a>
      </div>
    </div>
  )
}
const HeaderLogo = () => {
  return (
    <div className='flex items-center'>
      <a href='/'>
        <img src={logo} className='h-10 lg:h-14 md:h-12' alt='logo' />
      </a>
    </div>
  )
}

const Header = ({ active, toggleMenu, justNav = false, pathName, picAsBg = false, picBg, centerText = '' }) => {
  const [loading, setLoading] = React.useState(true)
  const videoRef = React.createRef()

  if (justNav) {
    return (
      <header className='flex w-full bg-primary'>
        <div className='flex items-center justify-between absolute w-full p-4 lg:p-8 md:p-6 z-100'>
          <HeaderLogo />
          <HeaderNavigation pathName={pathName} active={active} toggleMenu={toggleMenu} />
        </div>
      </header>
    )
  }

  return (
    <header className='flex h-screen w-full'>
      {picAsBg
        ? <PictureBackground pic={picBg} />
        : <VideoBackground loading={loading} setLoading={setLoading} videoRef={videoRef} />}
      <div className='flex items-center justify-between w-full p-4 lg:p-8 md:p-6 z-100 absolute'>
        <HeaderLogo />
        <HeaderNavigation pathName={pathName} active={active} toggleMenu={toggleMenu} />
      </div>
      <div className={`absolute flex flex-col justify-center items-center w-full h-screen text-white ${!loading || picAsBg ? 'bg-gradient-to-b from-transparent via-transparent to-primary opacity-100' : ''}`}>
        {centerText}
      </div>
    </header>
  )
}

const VideoBackground = ({ videoRef, loading, setLoading }) => {
  useEffect(() => {
    if (videoRef && videoRef.current) {
      videoRef.current.addEventListener('loadeddata', () => {
        setLoading(false)
      })
    }
    return () => {
      if (videoRef && videoRef.current) {
        videoRef.current.removeEventListener('loadeddata', () => {})
      }
    }
  }, [videoRef])

  return (
    <div className='bg-primary w-full h-screen'>
      {/* <iframe src={process.env.PUBLIC_URL + '/loop_mp4.mp4'} className='object-cover w-full h-screen'> */}
      <video
        ref={videoRef}
        playsInline
        autoPlay
        muted
        loop
        style={{
          opacity: loading ? 0 : 1,
          transition: 'opacity, 2s ease-in-out'
        }}
        className='object-cover w-full h-screen'
      >
        <source src='https://storage.googleapis.com/dphns-c7692.appspot.com/loop_mp4.mp4' type='video/mp4' />
      </video>
      {/* </iframe> */}
    </div>
  )
}

const PictureBackground = ({ pic }) => {
  const classNames = isiOS()
    ? 'h-screen md:mb-0 w-full flex items-center justify-center flex-col bg-scroll bg-no-repeat bg-center bg-cover pb-24'
    : 'h-screen md:mb-0 w-full flex items-center justify-center flex-col bg-fixed bg-no-repeat bg-center bg-cover pb-24'
  return (
    <div className='bg-primary w-full h-screen'>
      <div
        className={classNames}
        style={{ backgroundImage: `url(${pic})`, transition: 'opacity, 2s ease-in-out' }}
      />
    </div>
  )
}

export default Header
