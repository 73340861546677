import React from 'react'
import { Transition } from '@headlessui/react'
import Footer from 'Components/Footer'

const Menu = ({ toggleMenu }) => {
  return (
    <div className='fixed block top-0 left-0 w-full h-screen bg-secondary z-30'>
      <Transition.Child
        enter='transition ease-in-out duration-700 transform'
        enterFrom='translate-y-full'
        enterTo='-translate-y-0'
        leave='transition ease-in-out duration-300 transform'
        leaveFrom='-translate-y-0'
        leaveTo='translate-y-full'
      >
        <div className='fullHeightNoHeader w-full text-white flex justify-center items-center flex-col mt-8'>
          <ul className='flex flex-col justify-between text-center'>
            <li className='font-large text-3xl mb-4'><a href='/'>home</a></li>
            <li className='font-large text-3xl mb-4'><a href='/film'>film</a></li>
            <li className='font-large text-3xl mb-4'><a href='/about'>about</a></li>
            <li className='font-large text-3xl mb-4'><a href='/contact'>contact</a></li>
          </ul>
          <div>
            <Footer withCopyright={false} withLogo={false} footerPrimary={false} />
          </div>
        </div>
      </Transition.Child>
    </div>

  )
}

const PageTemplate = ({ Header, Content, Footer, toggleMenu, active }) => {
  return (
    <div className=''>
      {Header}
      {Content}
      {Footer}
      <Transition
        show={active}
        enter='transition-opacity duration-300'
        enterFrom='opacity-0'
        enterTo='opacity-100'
        leave='transition-opacity duration-300'
        leaveFrom='opacity-100'
        leaveTo='opacity-0'
      >
        <Menu toggleMenu={toggleMenu} />
      </Transition>
    </div>
  )
}

export default PageTemplate
