import React from 'react'

import StartkraftTN from 'assets/film/Startkraft/tn.jpg'
import KeepOnMovingTN from 'assets/film/KeepOnMoving/tn.jpg'

import Changing1 from 'assets/film/GoodPosture/changing_1.jpg'
import Changing2 from 'assets/film/GoodPosture/changing_2.jpg'
import Changing3 from 'assets/film/GoodPosture/changing_3.jpg'
import Changing4 from 'assets/film/GoodPosture/changing_4.jpg'
import ChangingTN from 'assets/film/GoodPosture/changing_tn.jpg'

import LastTime1 from 'assets/film/GoodPosture/lasttime_1.jpg'
import LastTime2 from 'assets/film/GoodPosture/lasttime_2.jpg'
import LastTime3 from 'assets/film/GoodPosture/lasttime_3.jpg'
import LastTime4 from 'assets/film/GoodPosture/lasttime_4.jpg'
import LastTimeTN from 'assets/film/GoodPosture/lasttime_tn.jpg'

import KOM1 from 'assets/film/KeepOnMoving/1.jpg'
import KOM2 from 'assets/film/KeepOnMoving/2.jpg'
import KOM3 from 'assets/film/KeepOnMoving/3.jpg'
import KOM4 from 'assets/film/KeepOnMoving/4.jpg'

import Novaa1 from 'assets/film/Novaa/Novaa_Futurist_Still_1.jpg'
import Novaa2 from 'assets/film/Novaa/Novaa_Futurist_Still_2.jpg'
import Novaa3 from 'assets/film/Novaa/Novaa_Futurist_Still_3.jpg'
import Novaa4 from 'assets/film/Novaa/Novaa_Futurist_Still_4.jpg'
import NovaaTN from 'assets/film/Novaa/tn.jpg'

import NovaaBTS1 from 'assets/film/Novaa/NovaBTS_1.jpg'
import NovaaBTS2 from 'assets/film/Novaa/NovaBTS_2.jpg'
import NovaaBTS3 from 'assets/film/Novaa/NovaBTS_3.jpg'
import NovaaBTS4 from 'assets/film/Novaa/NovaBTS_4.jpg'
import NovaaBTS5 from 'assets/film/Novaa/NovaBTS_5.jpg'
import NovaaBTS6 from 'assets/film/Novaa/NovaBTS_6.jpg'

import Ocelots1 from 'assets/film/Ocelots/Ocelots_Still_1.jpg'
import Ocelots2 from 'assets/film/Ocelots/Ocelots_Still_2.jpg'
import Ocelots3 from 'assets/film/Ocelots/Ocelots_Still_3.jpg'
import Ocelots4 from 'assets/film/Ocelots/Ocelots_Still_4.jpg'
import OcelotsTN from 'assets/film/Ocelots/tn.jpg'

import OlmoMathilda1 from 'assets/film/OlmoMathilda/OlmoMathilda_MS_Still_1.jpg'
import OlmoMathilda2 from 'assets/film/OlmoMathilda/OlmoMathilda_MS_Still_2.jpg'
import OlmoMathilda3 from 'assets/film/OlmoMathilda/OlmoMathilda_MS_Still_3.jpg'
import OlmoMathilda4 from 'assets/film/OlmoMathilda/OlmoMathilda_MS_Still_4.jpg'
import OlmoMathildaTN from 'assets/film/OlmoMathilda/tn.jpg'

import Startkraft1 from 'assets/film/Startkraft/1.jpg'
import Startkraft2 from 'assets/film/Startkraft/2.jpg'
import Startkraft3 from 'assets/film/Startkraft/3.jpg'
import Startkraft4 from 'assets/film/Startkraft/4.jpg'

import TroubleAhead1 from 'assets/film/TroubleAhead/TroubleAhead_Still_1.jpg'
import TroubleAhead2 from 'assets/film/TroubleAhead/TroubleAhead_Still_2.jpg'
import TroubleAhead3 from 'assets/film/TroubleAhead/TroubleAhead_Still_3.jpg'
import TroubleAhead4 from 'assets/film/TroubleAhead/TroubleAhead_Still_4.jpg'
import TroubleAheadTN from 'assets/film/TroubleAhead/tn.jpg'

import Paradise1 from 'assets/film/Paradise/1.jpg'
import Paradise2 from 'assets/film/Paradise/2.jpg'
import Paradise3 from 'assets/film/Paradise/3.jpg'
import Paradise4 from 'assets/film/Paradise/4.jpg'
import Paradise5 from 'assets/film/Paradise/5.jpg'
import Paradise7 from 'assets/film/Paradise/7.jpg'
import Paradise8 from 'assets/film/Paradise/8.jpg'
import Paradise9 from 'assets/film/Paradise/9.jpg'
import ParadiseTN from 'assets/film/Paradise/tn.jpg'

import ParadiseBTS1 from 'assets/film/Paradise/dphns_small_thumbnail_website_1.jpg'
import ParadiseBTS2 from 'assets/film/Paradise/dphns_small_thumbnail_website_2.jpg'
import ParadiseBTS3 from 'assets/film/Paradise/dphns_small_thumbnail_website_3.jpg'
import ParadiseBTS4 from 'assets/film/Paradise/dphns_small_thumbnail_website_4.jpg'
import ParadiseBTS5 from 'assets/film/Paradise/dphns_small_thumbnail_website_5.jpg'
import ParadiseBTS6 from 'assets/film/Paradise/dphns_small_thumbnail_website_6.jpg'
import ParadiseBTS7 from 'assets/film/Paradise/dphns_small_thumbnail_website_7.jpg'
import ParadiseBTS8 from 'assets/film/Paradise/dphns_small_thumbnail_website_8.jpg'

import MDBK1 from 'assets/film/MDBK_Neo_Rauch_und_Andreas_Gorsky/Rauch&Gursky_Still_1.jpg'
import MDBK2 from 'assets/film/MDBK_Neo_Rauch_und_Andreas_Gorsky/Rauch&Gursky_Still_2.jpg'
import MDBK3 from 'assets/film/MDBK_Neo_Rauch_und_Andreas_Gorsky/Rauch&Gursky_Still_3.jpg'
import MDBK4 from 'assets/film/MDBK_Neo_Rauch_und_Andreas_Gorsky/Rauch&Gursky_Still_4.jpg'
import MDBKTN from 'assets/film/MDBK_Neo_Rauch_und_Andreas_Gorsky/tn.jpg'

import HSHARZ1 from 'assets/film/HS_Harz/1.jpg'
import HSHARZ2 from 'assets/film/HS_Harz/2.jpg'
import HSHARZ3 from 'assets/film/HS_Harz/3.jpg'
import HSHARZ4 from 'assets/film/HS_Harz/4.jpg'
import HSHARZTN from 'assets/film/HS_Harz/tn.jpg'

const getModalText = ({ artist, caption }) => {
  return (
    <div className='w-full p-2 h-full'>
      <div className='font-large'>{artist}</div>
      <div className='font-basic'>{caption}</div>
    </div>
  )
}

const ChangingPics = [Changing1, Changing2, Changing3, Changing4]
const LastTimePics = [LastTime1, LastTime2, LastTime3, LastTime4]
const KOMPics = [KOM1, KOM2, KOM3, KOM4]
const NovaaPics = [Novaa1, Novaa2, Novaa3, Novaa4]
const NovaaBTS = [NovaaBTS1, NovaaBTS2, NovaaBTS3, NovaaBTS4, NovaaBTS5, NovaaBTS6]
const OcelotsPics = [Ocelots1, Ocelots2, Ocelots3, Ocelots4]
const ParadisePics = [Paradise1, Paradise2, Paradise3, Paradise4, Paradise5, Paradise7, Paradise8, Paradise9]
const ParadiseBTS = [ParadiseBTS1, ParadiseBTS2, ParadiseBTS3, ParadiseBTS4, ParadiseBTS5, ParadiseBTS6, ParadiseBTS7, ParadiseBTS8]
const OlmoMathildaPics = [OlmoMathilda1, OlmoMathilda2, OlmoMathilda3, OlmoMathilda4]
const StartkraftPics = [Startkraft1, Startkraft2, Startkraft3, Startkraft4]
const TroubleAheadPics = [TroubleAhead1, TroubleAhead2, TroubleAhead3, TroubleAhead4]
const MDBKPics = [MDBK1, MDBK2, MDBK3, MDBK4]
const HSHARZPICS = [HSHARZ1, HSHARZ2, HSHARZ3, HSHARZ4]

export const MUSIC_VIDEOS = [
  {
    vimeoId: 650393331,
    pics: ParadisePics,
    bts: ParadiseBTS,
    makingOfVimeo: 650418029,
    thumbnail: ParadiseTN,
    text: getModalText({
      artist: 'Paul-Lino - Paradise',
      caption: `This piece of magic comes deep from our hearts. „Writing a story, that is taken from life was my goal.
Telling the realationship of two wonderful people also means, that there never is everything good.
Life isn't easy, life isn't always nice.

But coming to the conclusion, that also in the bad and hard times, there are things that are wonderful, was the key to tell the story
of the song from my dear friend Paul-Lino in form of pictures.

It was an enriching, fluently process of finding the right conditions, the right actress, the right people who can make this
kind of emotional and deep script become reality.

It was like, all the things attracted each other, everything merged into one another,
almost by itself and that was very overwhelming.

I'm so unbelievable happy with the result, and hope you enjoy watching this little piece of magic.“`
    }),
    credits: `
directed & produced by: Richard Adam
starring: Muriel Bielenberg, Paul-Lino, Klaus-Dieter Weichert
production management: diaphanous
1st ad: Eleanor Großhennig
cinematography: Tim Pfautsch
1st ac: Marian Röder
gaffer: Philipp Fechner
bestboy: Matthias Koch
styling: Kieu Anh Duong
costume: Paula Fischer
colorist & vfx artist: Matthias Koch
edit: Richard Adam
runner: Stefanie Heinzig, Julian Müller
bts: Axel Warg

special thanks to: Salome Adam, Lumalenscape GmbH, Gemeindeverwaltung Großpösna,
Dorf- und Seenentwicklungsgesellschaft Großpösna GmbH`

  },
  {
    vimeoId: 648580446,
    pics: ChangingPics,
    thumbnail: ChangingTN,
    text: getModalText({
      artist: "Good Posture - Changin'",
      caption: `We filmed this piece only with a drone, which was not easy, because the weather had to be in perfect condition for getting the right shots with the lighting we needed for the mood of the song.`
    }),
    credits: `
cinematography & edit: Richard Adam
starring & concept: Joel Randles
colorist: Matthias Koch
titles: Sophie Boche`
  },
  {
    vimeoId: 630441064,
    pics: LastTimePics,
    thumbnail: LastTimeTN,
    text: getModalText({
      artist: 'Good Posture - Last Time',
      caption: `This one is very special, because it's just one take. So you will find no cut in the whole scene, which makes this music video very lively and approachable.`
    }),
    credits: `
cinematography: Richard Adam
starring & concept: Joel Randles
runner: Fritz Lord
colorist: Matthias Koch
titles: Sophie Boche`
  }
]

export const COMMERCIALS = [
  {
    vimeoId: 653700594,
    pics: StartkraftPics,
    thumbnail: StartkraftTN,
    text: getModalText({
      artist: 'Startkraft',
      caption: 'This one is a perfect example for combining 3D with real footage. We filmed all the plants and herbs in a studio and afterwards all the 3D shots where matched in terms of lighting and structure to the footage we already got.'
    }),
    credits: `
production: diaphanous
cinematography: Richard Adam & Matthias Koch
edit & sounddesign: Richard Adam
colorist: Matthias Koch`
  },
  {
    vimeoId: 711995594,
    pics: HSHARZPICS,
    makingOfVimeo: 711534530, 
    thumbnail: HSHARZTN,
    text: getModalText({
      artist: 'Imagefilm Hochschule Harz',
      caption: `With this film, students, alumni and professors invite all interested parties to take a look
"Behind the Scenes" of the Bachelor's degree in Business Informatics. Our task was to
illuminate all facets of the course from as many perspectives as possible in the short time of
the film.`
    }),
    credits: `production: diaphanous
director: Axel Warg, Matthias Koch
cinematography: Axel Warg, Matthias Koch
edit: Matthias Koch, Axel Warg
sounddesign: Richard Adam
color grading: Matthias Koch, Axel Warg`
  },
  {
    vimeoId: 701995587,
    pics: MDBKPics,
    thumbnail: MDBKTN,
    text: getModalText({
      artist: 'MdbK Neo Rauch & Andreas Gursky',
      caption: "We had the honor of accompanying Neo Rauch and Andreas Gursky through the MdbK Leipzig and recording an interesting conversation about creating images in photography and painting. They spoke about the „Old Masters“ and what connects them to each other. Feel free to watch the full video on the MdbK's Youtube Channel."
    }),
    credits: `
production: diaphanous
cinematography: Richard Adam & Matthias Koch
edit & sounddesign: Richard Adam
colorist: Matthias Koch`
  },
  {
    vimeoId: 653702765,
    pics: KOMPics,
    thumbnail: KeepOnMovingTN,
    text: getModalText({
      artist: 'Keep On Moving',
      caption: 'Totally different types of sports, different types of people. Our task was to get them all together and to send the message all of them have in mind: Stay focused and keep on moving to be a master.'
    }),
    credits: `
production: diaphanous
idea: Lena Startorius & Matthias Koch
director & production management: Lena Sartorius
dop & editing: Matthias Koch
1st ac & set audio: Benjamin Wehlend
2nd ac: Tim Stakelbeck
styling: Ploypailin Bayer
bts: Lisa Renz
starring: Niko Jaskowiak, Lea & Jana Poludniak, Christian Harenberg, Lauritz Manthei,
Marina Rosensprung`
  }
]

export const LIVESESSIONS = [
  {
    vimeoId: 497345895,
    pics: NovaaPics,
    bts: NovaaBTS,
    thumbnail: NovaaTN,
    text: getModalText({
      artist: 'Novaa - The Futurist',
      caption: 'We met her in a forrest near Leipzig, where we captured this special one shot for you, so every little second happens live in this moment. „The Futurist“ is a song of Novaa\'s album that dropped out 2020.'
    }),
    credits: `
production: diaphanous
cinematography: Richard Adam
gaffer & colorist: Matthias Koch
sound recording & mixing: Jakob Bethmann
sound assistant: Bjarne Wiesner
set manager: Katharina Jung
musicians: Antonia Rug, Hannah Wiese, Lorenz Stegmaier`
  },
  {
    vimeoId: 482286157,
    pics: OcelotsPics,
    thumbnail: OcelotsTN,
    text: getModalText(
      {
        artist: 'The Ocelots - The Switch',
        caption: 'We met these twins named Ashley and Brandon in a rundown room in Leipzig West, where they played "The Switch", which we captured as a oneshot. Enjoy with headphones.'
      }
    ),
    credits: `
production: diaphanous
cinematography: Richard Adam
1st ac: Tobias Witte
gaffer: Tim Pfautsch
best boy: Aaron Siebert
colorist: Matthias Koch
sound recording & mixing: Jakob Bethmann
musicians: Brandon & Ashley Watson`
  },
  {
    vimeoId: 482288299,
    pics: OlmoMathildaPics,
    thumbnail: OlmoMathildaTN,
    text: getModalText({
      artist: 'Olmo Mathilda - Melancholy Sophie',
      caption: 'Francesco Lo Guidice and Frank Dillane, two friends that we met at Herrmanns-Cave in the Harz Nationalpark. The song they play perfectly matches with the surrounding and dives deep into the mood of a special place like this.'
    }),
    credits: `
production: diaphanous
cinematography: Richard Adam
gaffer & colorist: Matthias Koch
runner: Lena Sartorius, Julian Kuhrt
sound recording: Richard Adam
sound mixing: Jonas von den Benken
edit: Richard Adam
musicians: Francesco Lo Giudice, Frank Dillane`
  },
  {
    vimeoId: 482069035,
    pics: TroubleAheadPics,
    thumbnail: TroubleAheadTN,
    text: getModalText({
      artist: 'Trouble Ahead',
      caption: 'These guys we also met in our hometown Leipzig to record this wonderful vintage session for you. They are influenced by many old musicians, and one of their favorites is Ruth Brown, why they covered his song As Long As I\'m Moving.'
    }),
    credits: `
cinematography & edit: Richard Adam
gaffer: Tim Pfautsch
sound engineer: Ian Cox
colorist: Matthias Koch
musicians: Anja Tschenisch, Christoph Hennebeil, Max Möbius, Martin Knöchelmann`
  }
]

export const ALL_FILMS = [
  ...MUSIC_VIDEOS,
  ...COMMERCIALS,
  ...LIVESESSIONS
]
