import React from "react";
import PageTemplate from "Components/PageTemplate";
import Header from "Components/Header";
import Footer from "Components/Footer";
import Pic1 from "assets/about/1.jpg";
import { isMobile } from "react-device-detect";
import About1 from "assets/about/about_1.jpg";
import About2 from "assets/about/about_2.jpg";
import About3 from "assets/about/about_3.jpg";
import About4 from "assets/about/about_4.png";

export default function About(props) {
  const pathName = props?.location?.pathname;
  const [active, setActive] = React.useState(false);
  const toggleMenu = () => {
    setActive(!active);
    !active
      ? document.body.classList.add("overflow-hidden")
      : document.body.classList.remove("overflow-hidden");

    return true;
  };

  return (
    <PageTemplate
      active={active}
      toggleMenu={toggleMenu}
      Header={
        <Header
          centerText={<CenterText />}
          pathName={pathName}
          active={active}
          toggleMenu={toggleMenu}
          picAsBg
          picBg={Pic1}
        />
      }
      Content={<AboutContent />}
      Footer={<Footer />}
    />
  );
}

const AboutContent = () => {
  const videoHeight = isMobile ? "200px" : "300px";
  return (
    <div className="min-h-screen w-full bg-primary text-white flex items-center justify-center flex-col pt-24 pb-48">
      <div className="pl-12 pr-12 mb-20">
        <div className="font-basic text-xl text-center mt-4">
          <p className="italic text-xl text-center">diaphanous</p>
          <p className="whitespace-pre-line">
            is a merger of young movie and music loving creatives
          </p>
          <p className="whitespace-pre-line">based in Leipzig, Germany</p>
        </div>
        <div className="font-basic text-xl mt-8 text-center">
          <p className="whitespace-pre-line">
            Our aim is to create cinematic high-quality films
          </p>
          <p className="whitespace-pre-line">
            with project related teams coming from
          </p>
          <p className="whitespace-pre-line">
            our personal network of very motivated humans.
          </p>
        </div>
        <div className="font-basic text-xl mt-8 text-center">
          <p className="whitespace-pre-line">
            May it be music videos, commercials or live sessions.
          </p>
          <p className="whitespace-pre-line">
            In the end what matters to us most is to create audiovisual content
          </p>
          <p className="whitespace-pre-line">that has meaning and depth.</p>
          <p className="whitespace-pre-line">
            Either visually exciting, thoughtful, intimate or
          </p>
          <p className="whitespace-pre-line">
            touching films that contain moments we all like to be part of.
          </p>
        </div>
        <div className="font-basic text-xl mt-8 text-center">
          <p className="whitespace-pre-line">
            Our expertise covers all areas of film production as well as brand
            development.
          </p>
          <p className="whitespace-pre-line">
            Especially today, when development is racing and you always have to
            be up to date,
          </p>
          <p className="whitespace-pre-line">
            we see ourselves as digital mediators between our customers and
            their customers.
          </p>
        </div>
        <div className="font-basic text-xl mt-8 text-center">
          <p className="whitespace-pre-line">
            The core of our team has been working together
          </p>
          <p className="whitespace-pre-line">
            since media class in high school.
          </p>
          <p className="whitespace-pre-line">
            We have since then gathered a lot of experience,
          </p>
          <p className="whitespace-pre-line">
            were able to film in amazing locations and met many interesting
            people.
          </p>
        </div>
        <div className="font-basic text-xl mt-8 text-center">
          <p className="whitespace-pre-line">
            Still, there are many more things
          </p>
          <p className="whitespace-pre-line">
            that will be part of diaphanous in the future.
          </p>
        </div>
        <div className="font-basic text-xl mt-8 text-center">
          <p className="whitespace-pre-line">
            If you are interested in working with us,
          </p>
          <p className="whitespace-pre-line">
            just press the contact field and tell us what’s on your mind.
          </p>
        </div>
      </div>

      <p className="font-bold text-xl text-center mb-12">team</p>

      <div className="flex flex-wrap gap-4 flex-col md:flex-row w-3/5 justify-around">
        <div className="mb-12 flex justify-center items-center flex-col">
          {/* <video className='object-cover z-100 w-48 h-48 md:w-52 md:h-52 rounded-full video-about-item' playsInline autoPlay muted loop>
            <source
              src='https://storage.googleapis.com/dphns-c7692.appspot.com/about_3.mp4'
              type='video/mp4'
            />
          </video> */}
          <img
            alt="about_1"
            className="object-cover z-100 w-48 h-48 md:w-52 md:h-52 rounded-full video-about-item"
            src={About3}
          />
          <div className="mt-4">
            <p className="font-bold text-center text-xl">richard adam</p>
            <p className="font-basic text-center">director</p>
            <p className="font-basic text-center">producer</p>
            <p className="font-basic text-center">marketer</p>
          </div>
        </div>
        <div className="mb-12 flex justify-center items-center flex-col">
          {/* <video
            className="object-cover z-100 w-48 h-48 md:w-52 md:h-52 rounded-full video-about-item"
            playsInline
            autoPlay
            muted
            loop
          >
            <source
              src="https://storage.googleapis.com/dphns-c7692.appspot.com/about_2.mp4"
              type="video/mp4"
            />
          </video> */}
          <img
            alt="about_2"
            className="object-cover z-100 w-48 h-48 md:w-52 md:h-52 rounded-full video-about-item"
            src={About2}
          />
          <div className="mt-4">
            <p className="font-bold text-center text-xl">matthias koch</p>
            <p className="font-basic text-center">vfx-artist</p>
            <p className="font-basic text-center">colorist</p>
            <p className="font-basic text-center">cinematography</p>
          </div>
        </div>
        <div className="mb-12 flex items-center flex-col">
          {/* <video
            className="object-cover z-100 w-48 h-48 md:w-52 md:h-52 rounded-full video-about-item"
            playsInline
            autoPlay
            muted
            loop
          >
            <source
              src="https://storage.googleapis.com/dphns-c7692.appspot.com/about_1.mp4"
              type="video/mp4"
            />
          </video> */}
          <img
            alt="about_1"
            className="object-cover z-100 w-48 h-48 md:w-52 md:h-52 rounded-full video-about-item"
            src={About1}
          />
          <div className="mt-4">
            <p className="font-bold text-center text-xl">jakob bethmann</p>
            <p className="font-basic text-center">sound-engineer</p>
          </div>
        </div>
        <div className="mb-12 flex items-center flex-col">
          {/* <video
            className="object-cover z-100 w-48 h-48 md:w-52 md:h-52 rounded-full video-about-item"
            playsInline
            autoPlay
            muted
            loop
          >
            <source
              src="https://storage.googleapis.com/dphns-c7692.appspot.com/about_1.mp4"
              type="video/mp4"
            />
          </video> */}
          <img
            alt="about_4"
            className="object-cover z-100 w-48 h-48 md:w-52 md:h-52 rounded-full video-about-item"
            src={About4}
          />
          <div className="mt-4">
            <p className="font-bold text-center text-xl">axel warg</p>
            <p className="font-basic text-center">art director</p>
            <p className="font-basic text-center">editor</p>
          </div>
        </div>
      </div>
    </div>
  );
};

const CenterText = () => {
  return (
    <>
      <div className="font-large text-3xl">we are diaphanous.</div>
    </>
  );
};
