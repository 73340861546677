import React from 'react'
import Footer from 'Components/Footer'
import Header from 'Components/Header'
import PageTemplate from 'Components/PageTemplate'
import Changers from 'assets/partner/changers.png'
import Delitzsch from 'assets/partner/delitzsch.png'
import hharz from 'assets/partner/hharz.png'
import kommhaus from 'assets/partner/kommhaus.png'
import kultursommer from 'assets/partner/kultursommer.png'
import leipzig from 'assets/partner/leipzig.png'
import lvz from 'assets/partner/lvz.png'
import Mdbk from 'assets/partner/Mdbk.png'
import mindsteps from 'assets/partner/mindsteps.png'
import startkraft from 'assets/partner/startkraft.png'

const Partners = [
  Changers, hharz, leipzig, lvz, Mdbk, Delitzsch, mindsteps, startkraft, kommhaus, kultursommer,
  Changers, hharz, leipzig, lvz, Mdbk, Delitzsch, mindsteps, startkraft, kommhaus, kultursommer
]

const HomeContent = () => {
  return (
    <section className='bg-primary'>
      <div className='flex items-center justify-center text-white font-large pt-32 w-full'>
        <div className='mb-48 pl-8 pr-4 md:pl-0 md:pr-0'>
          <p className='italic text-xl'>diaphanous</p>
          <p className='font-basic text-xl'>
            is a merger of young movie and music loving creatives
            based in Leipzig, Germany
          </p>
        </div>
      </div>
      <PartnerSlider />
    </section>
  )
}

{ /* <div className='w-1/2 h-screen flex justify-center flex-col'> */ }

const PartnerSlider = () => {
  return (
    <div className='slider pb-48 w-5/6 md:w-3/4'>
      <div className='slide-track'>
        {Partners.map((item, i) => {
          return (
            <div key={i} className='h-12 w-32 md:w-64'>
              <img className='w-20 md:w-24' src={item} />
            </div>
          )
        })}
      </div>
    </div>
  )
}

const Home = (props) => {
  const pathName = props?.location?.pathname
  const [active, setActive] = React.useState(false)

  const toggleMenu = () => {
    setActive(!active)
    !active
      ? document.body.classList.add('overflow-hidden')
      : document.body.classList.remove('overflow-hidden')

    return true
  }

  return (
    <PageTemplate
      active={active}
      toggleMenu={toggleMenu}
      Header={<Header centerText={<CenterText />} pathName={pathName} withVideo active={active} toggleMenu={toggleMenu} />}
      Content={<HomeContent />}
      Footer={<Footer />}
    />
  )
}

const CenterText = () => {
  return (
    <>
      <div className='font-large text-2xl leading-3'>we create</div>
      <div className='font-large text-4xl'>art to touch.</div>
      <div className='font-basic text-xl italic'>diaphanous</div>
    </>
  )
}

export default Home
