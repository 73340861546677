import React from 'react'
import PageTemplate from 'Components/PageTemplate'
import Header from 'Components/Header'
import Footer from 'Components/Footer'
import MailTo from 'Components/MailTo'

export default function Contact (props) {
  const pathName = props?.location?.pathname
  const [active, setActive] = React.useState(false)

  const [emailData, setData] = React.useState({
    firstName: '',
    lastName: '',
    email: '',
    message: ''
  })

  const isDisabled = Object.keys(emailData).some(key => emailData[key] === '')

  const getEmailText = () => {
    return `${emailData.message}`
  }

  const getSubject = () => {
    return `${emailData.firstName} ${emailData.lastName}`
  }

  const changeData = ({ key, value }) => {
    setData({ ...emailData, [key]: value })
    return true
  }

  const toggleMenu = () => {
    setActive(!active)
    !active
      ? document.body.classNameList.add('overflow-hidden')
      : document.body.classNameList.remove('overflow-hidden')

    return true
  }

  return (
    <PageTemplate
      active={active}
      toggleMenu={toggleMenu}
      Header={<Header pathName={pathName} active={active} toggleMenu={toggleMenu} justNav />}
      Content={<div className='min-h-screen pt-16 pb-20 lg:pt-40 xl:pt-40 w-full bg-primary text-white flex items-center justify-center'>
        <Content changeData={changeData} getSubject={getSubject} getEmailText={getEmailText} isDisabled={isDisabled} />
      </div>}
      Footer={<Footer />}
    />
  )
}

const Content = ({ changeData, getSubject, getEmailText, isDisabled }) => {
  return (
    <form className='w-full max-w-lg p-12 font-basic'>
      <div className='flex flex-wrap -mx-3 mb-6'>
        <div className='w-full md:w-1/2 px-3 mb-6 md:mb-0'>
          <label className='block uppercase tracking-wide text-white text-xs font-bold mb-2' htmlFor='firstName'>
            First Name
          </label>
          <input onChange={(e) => changeData({ key: 'firstName', value: e.target.value })} className='appearance-none block w-full bg-gray-200 text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white' id='grid-first-name' type='text' placeholder='' />
          {/* <p className='text-red-500 text-xs italic'>Please fill out this field.</p> */}
        </div>
        <div className='w-full md:w-1/2 px-3'>
          <label className='block uppercase tracking-wide text-white text-xs font-bold mb-2' htmlFor='lastName'>
            Last Name
          </label>
          <input onChange={(e) => changeData({ key: 'lastName', value: e.target.value })} className='appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500' id='grid-last-name' type='text' placeholder='' />
        </div>
      </div>
      <div className='flex flex-wrap -mx-3 mb-6'>
        <div className='w-full px-3'>
          <label className='block uppercase tracking-wide text-white text-xs font-bold mb-2' htmlFor='email'>
            E-mail
          </label>
          <input onChange={(e) => changeData({ key: 'email', value: e.target.value })} className='appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500' id='email' type='email' />
        </div>
      </div>
      <div className='flex flex-wrap -mx-3 mb-6'>
        <div className='w-full px-3'>
          <label className='block uppercase tracking-wide text-white text-xs font-bold mb-2' htmlFor='message'>
            Message
          </label>
          <textarea onChange={(e) => changeData({ key: 'message', value: e.target.value })} className=' no-resize appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 h-48 resize-none' id='message' />
        </div>
      </div>

      <div className={`shadow ${isDisabled ? 'bg-gray-700' : 'bg-secondary'} text-white font-bold py-2 px-4 rounded ${isDisabled ? 'pointer-events-none' : 'cursor-pointer'}`}>
        <MailTo email='info@dphns.me' subject={getSubject()} body={getEmailText()}>Send</MailTo>
      </div>
    </form>

  )
}
