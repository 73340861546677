import React from "react";
import PageTemplate from "Components/PageTemplate";
import Header from "Components/Header";
import Footer from "Components/Footer";

export default function Imprint(props) {
  const pathName = props?.location?.pathname;
  const [active, setActive] = React.useState(false);

  const toggleMenu = () => {
    setActive(!active);
    !active
      ? document.body.classList.add("overflow-hidden")
      : document.body.classList.remove("overflow-hidden");

    return true;
  };

  return (
    <PageTemplate
      active={active}
      toggleMenu={toggleMenu}
      Header={
        <Header
          pathName={pathName}
          active={active}
          toggleMenu={toggleMenu}
          justNav
        />
      }
      Content={<ImprintContent />}
      Footer={<Footer />}
    />
  );
}

const ImprintContent = () => {
  return (
    <div className="min-h-screen w-full bg-primary text-white flex justify-center items-center flex-col pt-32 pb-24 pl-12 xl:pl-20 lg:pl-20">
      <div className="text-3xl font-large w-full">imprint</div>
      <div className="mt-2 font-basic w-full">
        information according to § 5 TMG
      </div>
      <div className="w-full flex justify-start">
        <div className="mt-8 flex flex-col md:flex-row lg:flex-row xl:flex-row w-4/5 justify-between">
          <div className="font-basic mt-4">
            <div className="font-large text-xl">address</div>
            <ul className="mt-2 font-basic">
              <li>Angaben gemäß §5 TMG</li>
              <li>Richard Adam</li>
              <li>Eilenburger Str. 56</li>
              <li>04509 Delitzsch</li>
            </ul>
            <div className="mt-2 font-large text-xl">contact</div>
            <div className="mt-2 font-basic">info@dphns.me</div>
          </div>
          <div className="mt-4 md:w-2/5 lg:w-2/5 xl:w-2/5">
            <div className="font-large text-xl">copyright</div>
            <div className="mt-2 font-basic">
              The content and works on these pages created by the website
              operator one subject to German copyright law. The reproduction,
              preocessing, distribution and any kind of exploitation outside the
              limits of copyright require the written consent of the respective
              author or creator. Downloads and copies of this website are only
              permitted for private, non-commercial use. Insofar as the content
              on this site was not created by the operator, the copyrights of
              third parties are observed. In particular contents of thirsd
              parties are marked as such. Should you nevertheless become aware
              of a copyright infringement, we would ask you to notify us
              accordingly. As soon as we become aware of legal violations, we
              will remove such content immediately.
            </div>
          </div>

          <div className="mt-4 mb-12">
            <div className="font-large text-xl">content responsibility</div>
            <div className="mt-2 font-basic">
              according to §55 RSTV: Richard Adam
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
