import React from 'react'

const Mailto = ({ email, subject = '', body = '', children }) => {
  let params = subject || body ? '?' : ''
  if (subject) params += `subject=${encodeURIComponent(subject)}`
  if (body) params += `${subject ? '&' : ''}body=${encodeURIComponent(body)}`
  return <a style={{ display: 'block', width: '100%', textAlign: 'center' }} href={`mailto:${email}${params}`}>{children}</a>
}

export default Mailto
