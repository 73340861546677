import * as d from './actionTypes'

const initialState = {
  isFetching: false,
  error: null,
  user: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case d.LOGIN_REQUESTED: {
      return { ...state, isFetching: true }
    }

    case d.LOGIN_SUCCEEDED: {
      const userData = action.payload
      return { ...state, isFetching: false, user: userData }
    }

    case d.LOGIN_FAILED: {
      return { ...state, error: action.payload.error.message }
    }

    case d.FETCH_USER_REQU: {
      return { ...state, isFetching: true }
    }

    case d.FETCH_USER_SUCC: {
      const userData = action.payload
      return { ...state, isFetching: false, user: userData }
    }

    case d.FETCH_USER_FAIL: {
      const error = action.payload.error
      return { ...state, error }
    }

    case d.CHANGE_USER_DATA: {
      const userData = action.payload
      return { ...state, user: userData }
    }

    default:
      return state
  }
}
