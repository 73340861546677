import React from "react";
import logo from "assets/logo.png";
import insta from "assets/instagram.svg";
import fb from "assets/facebook.svg";
import youtube from "assets/youtube.svg";
import tiktok from "assets/tiktok.svg";
import vimeo from "assets/vimeo.svg";

const Footer = ({
  isMenu = false,
  footerPrimary = true,
  withCopyright = true,
  withLogo = true,
}) => {
  return (
    <div
      className={`${
        footerPrimary ? "bg-primary" : "bg-secondary"
      } text-white p-6 flex flex-col justify-center items-center`}
    >
      {withLogo && (
        <div className="flex items-center">
          <img src={logo} className="h-12 lg:h-16 md:h-14" alt="logo" />
        </div>
      )}

      <div className="pt-12 flex justify-between items-center">
        <a href="https://www.youtube.com/channel/UCDXg9mKhQaaZ8_5u4j3iCnw">
          <img
            className="w-6 h-6 mr-4 filter-white cursor-pointer"
            src={youtube}
            alt="logo-yt"
          />
        </a>
        <a href="https://www.instagram.com/dphns.me/">
          <img
            className="w-6 h-6 mr-4 filter-white cursor-pointer"
            src={insta}
            alt="logo-insta"
          />
        </a>
        <a href="https://www.facebook.com/dphns.me">
          <img
            className="w-6 h-6 mr-4 filter-white cursor-pointer"
            src={fb}
            alt="logo-fb"
          />
        </a>
        <a href="https://www.tiktok.com/@dphns.me">
          <img
            className="w-6 h-6 mr-4 filter-white cursor-pointer"
            src={tiktok}
            alt="logo-tiktok"
          />
        </a>
        <a href="https://vimeo.com/dphns">
          <img
            className="w-6 h-6 mr-4 filter-white cursor-pointer"
            src={vimeo}
            alt="logo-vimeo"
          />
        </a>
      </div>
      {withCopyright && <div className="mt-6 font-basic">info@dphns.me</div>}
      {withCopyright && (
        <div className="mt-6 font-basic text-white w-40 flex justify-between flex-row">
          <a href="/policy">privacy policy</a>
          <a href="/imprint">imprint</a>
        </div>
      )}
      {withCopyright && (
        <div className="mt-8 font-basic text-white">2024 - diaphanous</div>
      )}
    </div>
  );
};

export default Footer;
